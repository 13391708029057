// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-html-jsx": () => import("./../../../src/pages/about.html.jsx" /* webpackChunkName: "component---src-pages-about-html-jsx" */),
  "component---src-pages-campus-recruitment-html-jsx": () => import("./../../../src/pages/campus-recruitment.html.jsx" /* webpackChunkName: "component---src-pages-campus-recruitment-html-jsx" */),
  "component---src-pages-case-html-jsx": () => import("./../../../src/pages/case.html.jsx" /* webpackChunkName: "component---src-pages-case-html-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-join-us-html-jsx": () => import("./../../../src/pages/join-us.html.jsx" /* webpackChunkName: "component---src-pages-join-us-html-jsx" */),
  "component---src-pages-recruitment-html-jsx": () => import("./../../../src/pages/recruitment.html.jsx" /* webpackChunkName: "component---src-pages-recruitment-html-jsx" */),
  "component---src-pages-rrc-html-jsx": () => import("./../../../src/pages/rrc.html.jsx" /* webpackChunkName: "component---src-pages-rrc-html-jsx" */)
}

